import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import TreeFillerBackground from "../TreeFillerBackground/index";
import "./index.scss";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
    overflowY: "scroll",
  },
  mainTextBox: {
    width: "100%",
    height: "100%",
    padding: "2.5vh 10vw",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    overflowY: "scroll",
  },
  title: {
    fontFamily: "davida",
    fontSize: window.innerWidth > 400 ? "2.75em" : "2.25em",
    textAlign: "center",
    width: "60%",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: "0 auto",
    marginBottom: "10px",
    maxWidth: "275px",
  },
  bodyText: {
    whiteSpace: "pre-line",
    marginTop: "2.5%",
    textAlign: "justify",
    textIndent: "30px",
    maxWidth: "600px",
    fontSize: window.innerWidth > 400 ? "16px" : "13px",
  },
}));

const bioP1 = "Four guys with dreams of playing the big stage, bringing a revival of rock and roll with modern energy and a uniquely classic sound. From the heart of Vancouver, we give you… Blue Arbutus!";
const bioP2 = "When guitarist Simon Barer put out a call to arms to start the most face-melting band around, drummer Wyatt Gilson, bassist James Alexander, and lead vocalist Ty Koch answered the call. Through their love of Led Zeppelin, Red Hot Chili Peppers, Black Keys, Radiohead and more, the guys quickly built up a repertoire of up-tempo, daring, and experimental material.";
const bioP3 = "The band’s self-recorded, self-produced, self-titled album features songs that span the sonic map, ranging from the can’t-help-but-sing-along “Dancing on the Chairs”, to the epic journey of “Deter”, to the foot-stomping blues rock of “Run Till they Catch Me” and “Lose Your Mind”.";
const bioP4 = "Never knowing where you’re going to be taken to next, Blue Arbutus shows hit the ground running, and will keep you on your feet until the final note, leaving you screaming for more.";
const bioP5 = "With hard hitting riffs, scorching vocals, bombastic drums, and bone rattling bass lines, Blue Arbutus is the up and coming band you can't afford to miss.";



export default function Bio() {
  const classes = useStyles();

  return (

    <Box className={classes.mainBox} >
      <TreeFillerBackground fadeWidth={1500}/>
      
      <Box className={classes.mainTextBox} >
        <Typography variant="h4" color="primary" className={classes.title}>Our Story</Typography>
        <Typography className={classes.bodyText} variant="body1" color="primary">{bioP1}</Typography>
        <Typography className={classes.bodyText} variant="body1" color="primary">{bioP2}</Typography>
        <Typography className={classes.bodyText} variant="body1" color="primary">{bioP3}</Typography>
        <Typography className={classes.bodyText} variant="body1" color="primary">{bioP4}</Typography>
        <Typography className={classes.bodyText} variant="body1" color="primary">{bioP5}</Typography>
      </Box>

    </Box>
  );
}