import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../styles/theme";
import "./styles.css";
import { JustifiedLayout } from "@egjs/react-infinitegrid";
import { SRLWrapper } from "simple-react-lightbox";
import fillerImage from "../../static/treeImage.JPG";
import Item from "./Item";
import Loading from "./loading";


const useStyles = makeStyles((theme) => ({
  mainDiv: {
    backgroundColor: theme.palette.secondary.hover,
    width: "100%",
  }
}));

function getNumberOfColumns(width) {
  if (width > 1200) {
    return 5;
  } else if (width > 925) {
    return 4;
  } else if (width > 376) { // 376 is width of iPhone SE according to safari
    return 3;
  }
  return 2;
}

function importAll(r) {
  // Must map to src for SRLWrapper to function
  return r.keys().map(r).map((r) => (
    {src: r}
  ))
}

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

// Load in images
var images = importAll(require.context('../../static/galleryImages', false, /\.(png|jpe?g|svg)$/));
shuffle(images)
// need to push a filler image, as JustifiedGrid doesn't seem to properly serve all assets in the array
images.push(fillerImage)

export default function Gallery(props) {
  const classes = useStyles();
  const justifiedRef = React.useRef();

  const [firstLayoutComplete, setFirstLayoutComplete] = useState(false);
  const [list, updateList] = useState([]);
  const [start, updateStart] = useState(0);

  function loadItems(groupKey, num) {
    const items = [];
    for (let i = 0; i < num; ++i) {
      const startActual = start + i
        items.push(
          <Item
            groupKey={groupKey} 
            source={images[startActual].src} 
            num={startActual} 
            key={start + i} 
            id={images[startActual].src} 
          />
        );
    }
    updateStart(start + num);
    return items;
  }

  function onAppend({ groupKey, startLoading, currentTarget }) {
    if (currentTarget.isProcessing()) {
      return;
    }
    if (list.length === images.length-1) {
      justifiedRef.current.ig.isOverflowScroll = false;
      return
    }
    startLoading();
    const items = loadItems((parseFloat(groupKey) || 0) + 1, getAppendNumber());
    const newList = list.concat(items)
    updateList(newList)
  };

  function getAppendNumber() {
    // var number = Math.floor((Math.random() * 6) + 1)+1;
    var number = images.length-1  // workaround until JustifiedGrid works correctly
    const newLength = list.length + number

    // if number+list.length exceeds array length, make number only get remaining images
    if (newLength >= images.length) {
      number = images.length-list.length-1
    }

    // if number would leave a single image left, increment the number to improve display
    if (list.length+number === images.length-2) {
      number = number + 1
    }

    return number
  }

  function onLayoutComplete({ isLayout, endLoading }) {
    !isLayout && endLoading();
    setFirstLayoutComplete(true);
  };

  function onError({error}) {
    // console.log("error is: ", error)
  };

  useEffect(() => {
    const resizeListener = () => {
      if (justifiedRef.current) {
        const layoutOptions = justifiedRef.current.ig._layout.options;
        layoutOptions.column = [0, getNumberOfColumns(window.innerWidth)];
      }
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  const lightboxOptions = {
    settings: {
      overlayColor: theme.palette.secondary.transparentGrey,
      autoplaySpeed: 3000,
      transitionSpeed: 300,
      lightboxTransitionSpeed: 0.01,
      slideAnimationType: "slide",
    },
    buttons: {
      backgroundColor: theme.palette.primary.transparent,
      iconColor: theme.palette.secondary.transparentWhite,
      showDownloadButton: false,
    },
    caption: {
      showCaption: false,
    },
    thumbnails: {
      thumbnailsContainerPadding: `0px 15px 5px 15px`,
      thumbnailsOpacity: .3,
      thumbnailsContainerBackgroundColor: theme.palette.secondary.transparentGrey,
      thumbnailsSize: ["150px", "115px"],
    },
    progressBar: {
      backgroundColor: theme.palette.secondary.transparentGrey,
      fillColor: theme.palette.primary.transparent,
    },
  };

  const gridStyle = {
    width: "100vw", 
    minHeight: `100vh`,
    padding: "10px",
    overflowY: "scroll",
  }

  return (
    <div className={classes.mainDiv} >
      {
        !firstLayoutComplete ?
        <Loading/>
        : null
      }
        <JustifiedLayout
            ref={justifiedRef}
            options={{
              isConstantSize: false,
              isEqualSize: false,
              transitionDuration: 0.2,
              isOverflowScroll: true,
              useRecycle: false,
              useFirstRender: true,
            }}
            layoutOptions={{
              margin: 5,
              column: [0,getNumberOfColumns(window.innerWidth)],
              align: "center",
            }}
            onAppend={onAppend}
            onLayoutComplete={onLayoutComplete}
            onImageError={onError}
            onContentError={onError}
            style={gridStyle}
          >
            {list}
        </JustifiedLayout>
        <SRLWrapper options={lightboxOptions} elements={images}/>
      </div>
  );
}

