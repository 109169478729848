import React from "react";
import {
  BrowserRouter as Router,
  withRouter
} from "react-router-dom";

// Material-UI
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from "./styles/theme.js";
import "./styles/styles.css";
import SimpleReactLightbox from "simple-react-lightbox";


import { App } from './components/App/App';

function Site() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <SimpleReactLightbox>
          <App />
        </SimpleReactLightbox>
      </Router>
    </ThemeProvider>
  );
}

export default withRouter(Site);
