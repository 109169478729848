import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

// Colours from: https://coolors.co/a76d60-76877d-197278-9df7e5-191919

const colours = {
    eerie_black: '#191919',
    bronze: '#A76D60',
    murkey_green: '#76877D',
    blue: '#081797',
    blueTransparent: 'rgba(8,23,151,0.8)',
    mint: '#9DF7E5',
    white: '#ffffff',
    greyTransparent: 'rgba(10,10,10,0.95)',
    whiteTransparent: 'rgba(255,255,255,0.8)',
    whiteHover: '#DADADA',
    red: red[600],
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colours.blue,
            transparent: colours.blueTransparent,
        },
        secondary: {
            main: colours.white,
            hover: colours.whiteHover,
            transparentGrey: colours.greyTransparent,
            transparentWhite: colours.whiteTransparent,
        },
        text: {
            primary: colours.eerie_black,
            secondary: colours.white,
        },
        error: {
            main: colours.red,
            secondary: red[200],
        }
    },
    paper: {
        background: colours.blue,
      }
});

export default theme;