import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import "./index.scss";
import TreeFillerBackground from "../TreeFillerBackground/index";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
    overflowY: "scroll",
  },
  mainTextBox: {
    width: "100%",
    height: "100%",
    padding: "2.5vh 10vw",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    overflowY: "scroll",
  },
  title: {
    fontFamily: "davida",
    fontSize: window.innerWidth > 400 ? "2.75em" : "2.25em",
    textAlign: "center",
    width: "60%",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: "0 auto",
    marginBottom: "10px",
    maxWidth: "275px",
  },
  bodyText: {
    whiteSpace: "pre-line",
    marginBottom: "25px",
    marginTop: "2.5%",
    textAlign: "center",
    maxWidth: "600px",
    fontSize: window.innerWidth > 400 ? "16px" : "13px",
  },
  covidEventTextEndSquiggle: {
    fontWeight: 100,
    textAlign: "center",
  },
}));

export default function Events() {
  const classes = useStyles();

  const bodyText =  `Due to Covid Restrictions,
    We cannot complete our mission
    Of playing shows for our fans,
    But we’ll be back as soon as we can.
    We wish our music could be heard,
    But rest assured,
    We’re working diligently behind the scenes,
    Cooking up, by any and all means,
    New music and experiences,
    That in all seriousness,
    We can’t wait to share with you.
    So stay tuned,
    ‘Cuz once normal life has resumed,
    We’ll be releasing an album or two,
    As our new and improved lineup
    Finally makes its triumphant debut.`;
  
  return (
    <Box className={classes.mainBox}>
      <TreeFillerBackground fadeWidth={1000}/>
      
      <Box className={classes.mainTextBox}>
          <Typography variant="h4" color="primary" className={classes.title}>Events</Typography>
          <Typography className={classes.bodyText} variant="body1" color="primary">{bodyText}</Typography>
          <Typography className={classes.covidEventTextEndSquiggle} variant="h3" color="primary">~</Typography>
      </Box>

    </Box>
  );
}