import React from "react";
import { useLightbox } from "simple-react-lightbox";

export default function Item({ num, source }) {
    const { openLightbox } = useLightbox()
    
    function shouldOpenLightbox() {
        openLightbox(num)
    }
  
    return (
        <div className="item">
            <div className="thumbnail">
                <img
                    src={source}
                    alt=""
                    onClick={shouldOpenLightbox}
                    draggable="false"
                    // loading="lazy"  // JustifiedLayout needs explicit width&height for this to work, will do later, https://github.com/naver/egjs-infinitegrid/wiki/Preprocess-Image-for-performance-boost
                />
            </div>
        </div>
    )
}