import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import Home from "../Home/index";
import Bio from "../Bio/index";
import Gallery from "../Gallery/index";
import Events from "../Events/index";
import Contact from "../Contact/index";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "../AppBar";
import FooterBar from "../FooterBar";


const useStyles = makeStyles((theme) => ({
  innerDiv: {
    position: "relative", 
    overflow: "scroll", 
    // backgroundColor: theme.palette.error.main, 
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  scrollDiv: {
    height: "2000px",
  }
}));


function App(props) {
  const classes = useStyles();
  const { pathname } = useLocation();

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const isMobile = dimensions.width <= 960;
  const windowOffset = isMobile ? 129 : 114

  const refreshStepSize = 10;
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, refreshStepSize);

    window.addEventListener("resize", debouncedHandleResize);

    return _ => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  
  return (
        <div>
          <AppBar />
            <div className={classes.innerDiv} style={{height: dimensions.height-windowOffset, width: dimensions.width}}>
              <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <Route exact path="/">
                  <Home/>
                </Route>
                <Route path="/bio">
                  <Bio dimensions={dimensions}/>
                </Route>
                <Route path="/gallery">
                  <Gallery/>
                </Route>
                <Route path="/events">
                  <Events />
                </Route>
                <Route path="/contact">
                  <Contact height={dimensions.height-windowOffset}/>
                </Route>
                <Route path="*">
                  <Home/>
                </Route>
              </Switch>
            </div>
          <FooterBar isMobile={isMobile}/>
        </div>
  );
}

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export {App};
// export default withRouter(App);
