import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import treeImage from "../../static/TreePlainSquare.jpg"
import treeImageFlipY from "../../static/TreePlainSquareFlipped.jpg"

import "./index.scss";

const useStyles = makeStyles((theme) => ({
  homeRoot: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.secondary.main, 
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "hidden",
  },
  treeImage: {
    width: "100%",
  }
}));

export default function Home() {
  const classes = useStyles();

  return (
      <div className={classes.homeRoot}>
          {window.innerHeight-114 > window.innerWidth ?
          <img src={treeImage} alt="" className={classes.treeImage} />
          :
          <img src={treeImage} alt="" style={{height: "100%"}}/>
          }
          {/* Will only be visible when screen height expands beyond height of main image */}
          <img src={treeImageFlipY} style={{width: "100%", opacity: 0.85}} alt=""/>
      </div>
  );
}
