import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import simon_railway2 from "../../static/galleryImages/simon_railway2.jpeg";


import Button from "@material-ui/core/Button";


import "./index.scss";

const useStyles = props => makeStyles( theme => ({
  mainBox: {
    width: "100vw",
    height: props.height,
    display: "flex",
    flexDirection: "column",
  },
  mainTextBox: {
    padding: "2.5vh 10vw",
    margin: "0 auto",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  title: {
    fontFamily: "davida",
    fontSize: window.innerWidth > 400 ? "2.75em" : "2.25em",
    textAlign: "center",
    width: "60%",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: "0 auto",
    marginBottom: "10px",
    maxWidth: "275px",
  },
  bodyText: {
    whiteSpace: "pre-line",
    marginTop: "25px",
    textAlign: "center",
    maxWidth: "600px",
    fontSize: window.innerWidth > 400 ? "16px" : "13px",
  },
  emailButton: {
    marginTop: "5vh",
    height: "50px",
    width: "50vw",
    maxWidth: "225px",
    [theme.breakpoints.down('sm')]: {
      width: "70%",
    },
  },
  imageBox: {
    display: "flex",
    minHeight: "30vh",
    minWidth: "100vw",
  },
  image: {
    objectFit: "contain",
    width: "100vw",
    margin: "2.5vh 0%",
    pointerEvents: "none",
  },
}));

export default function Contact(props) {
  const classes = useStyles(props)();

  return (
    <div className={classes.mainBox} >
      <Box className={classes.mainTextBox}>
        <Typography variant="h4" color="primary" className={classes.title}>Contact Us</Typography>

        <Typography variant="body1" color="primary" className={classes.bodyText}>
        For all inquiries regarding the Band, future shows and events, 
        booking information, merchandise, and media inquiries, 
        please contact us through our social media account or email us below:
        </Typography>
      
        <Button
          variant="contained"
          color="primary"
          target="_top"
          rel="noopener noreferrer"
          href={`mailto:bluearbutus@gmail.com`}
          className={classes.emailButton}
        >
          Send us an email!
        </Button>
      </Box>


      <Box className={classes.imageBox}>
        <img src={simon_railway2} alt="" className={classes.image}/>
      </Box>

    </div>
  );
}