import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import branchBlue from "../../static/BranchBlue.png";
import branchBlueFlip from "../../static/BranchBlueFlip.png";

const useStyles = makeStyles((theme) => ({
    fillerBox: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    imageBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      height: "100%",
      pointerEvents: "none",
    },
    image: {
      objectFit: "contain",
      objectPosition: "bottom",
      maxHeight: "70%"
    },
}));

export default function TreeFillerBackground({fadeWidth}) {
    const classes = useStyles();

    const [dynamicOpacity, setOpacity] = useState(getOpacity());
    const [dynamicWidthFiller, setWidthFiller] = useState(getWidth());
    const [dynamicWidthTree, setWidthTree] = useState((100-dynamicWidthFiller)/2);
    const [currentSize, setCurrentSize] = useState(window.innerWidth);
    const fillerBoxRef = React.useRef();

    function getOpacity() {
        const maxOpacity = 0.7
        if (window.innerWidth >= fadeWidth) {
            return maxOpacity
        } else {
            const newO = Math.pow((window.innerWidth / fadeWidth), 7) - (1-maxOpacity)
            return Math.max(newO, 0.14)
        }
    }

    function getWidth() {
        const widthDynamic = window.innerWidth/25 - 5     // 5 to offset the rate of increase for small size windows
        return Math.min(40, widthDynamic)
    }

    useEffect(() => {
        // required here and above to resolve hook dependency issue
        function getOpacityHook() {
            const maxOpacity = 0.7
            if (window.innerWidth >= fadeWidth) {
                return maxOpacity
            } else {
                const newO = Math.pow((window.innerWidth / fadeWidth), 7) - (1-maxOpacity)
                return Math.max(newO, 0.14)
            }
        }

        const resizeListener = () => {
            // throttle changes to greater than 10px
            const sizeDifference = Math.abs(currentSize-window.innerWidth)
            if (fillerBoxRef.current && sizeDifference > 10) {
            setOpacity(getOpacityHook())
            const newFillerWidth = getWidth()
            setWidthFiller(newFillerWidth)
            setWidthTree((100-newFillerWidth)/2)
            setCurrentSize(window.innerWidth)
            }
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [currentSize, fadeWidth])
  
    return (
        <>
            <Box 
                className={classes.imageBox} 
                style={{width: `${dynamicWidthTree}%`}}
            >
                <img src={branchBlue} alt="" className={classes.image} style={{opacity: dynamicOpacity}}/>
            </Box>

            <Box className={classes.fillerBox} ref={fillerBoxRef} 
            style={{minWidth: `${dynamicWidthFiller}%`}}
            ></Box>
            
            <Box 
                className={classes.imageBox} 
                style={{width: `${dynamicWidthTree}%`}}
            >
                <img src={branchBlueFlip} alt="" className={classes.image} style={{opacity: dynamicOpacity}}/>
            </Box>
        </>
    )
}