import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import { useLocation } from 'react-router-dom';
import whiteTree from "../../static/whiteTreeWithText.png";
import Box from '@material-ui/core/Box';
import "./index.scss";
import SocialButtons from "../SocialButtons/index";


const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    height: '100%',
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "65px",
    height: "65px",
  },
  toolBar: {
    minHeight: "65px",
    height: "65px",
  },
  menuButton: {
    '&:hover': {
      color: theme.palette.secondary.hover,
    },
  },
  title: {
    color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.hover,
      },
    fontSize: window.innerWidth > 300 ? "2.2em" : "1.8em",
    fontFamily: "davida",
  },
  tabs: {
    ...theme.mixins.toolbar,
    minWidth: 0,
    width: 'fit-content',
    margin: "auto",
    color: theme.palette.secondary.main,
  },
  tabsIndicator: {
    bottom: "3px",
  },
  tab: {
    ...theme.mixins.toolbar,
    minWidth: 0,
    '&:hover': {
      color: theme.palette.secondary.hover,
    },
  },
  listItemText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.45em',
    '&:hover': {
      color: theme.palette.secondary.hover,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    width: drawerWidth,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  treeImage: {
    width: "110%",
    '&:hover': {
      opacity: 0.8,
    },
  }
}));

function removeTrailingSlash(path) {
  return path.replace(/\/+$/, "")
}

export default function PrimaryAppBar() {
  const classes = useStyles();
  const history = useHistory();

  const { pathname } = useLocation();
  // account for case where actualLocation is the homepage
  // otherwise, trim trailing slash to match routing logic
  var actualPath = pathname
  if (actualPath !== "/") {
    actualPath = removeTrailingSlash(actualPath)
  }

  const [drawerState, setDrawerState] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    history.push(`${newValue}`);
  };

  const handlePageChange = title => () => {
    history.push(`${title}`);
    setDrawerState(false);
  };

  function handleDrawerToggle() {
    setDrawerState(!drawerState)
  }

  return (
    <div>
      <AppBar position="static" className={classes.appBar} >
        <Toolbar className={classes.toolBar}>
          <Button
              onClick={() => {
                history.push("/");
              }}
              className={classes.title}
          >
            Blue Arbutus
          </Button>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Grid container alignItems="center">
              <Tabs 
                    value={actualPath}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    classes={{ root: classes.tabs, indicator: classes.tabsIndicator }}
                  >
                <Tab classes={{ root: classes.tab }} label="Home" value="/" />
                <Tab classes={{ root: classes.tab }} label="Bio" value="/bio" />
                <Tab classes={{ root: classes.tab }} label="Gallery" value="/gallery"/>
                <Tab classes={{ root: classes.tab }} label="Events" value="/events"/>
                <Tab classes={{ root: classes.tab }} label="Contact" value="/contact" />
              </Tabs>
            </Grid>
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </div>
          
          <Hidden mdUp>    {/* Hidden will auto-hide the drawer when page reaches and exceeds medium size */}
          <Drawer 
            classes={{ paper: classes.drawerPaper }} 
            anchor={'right'} open={drawerState} 
            onClose={handleDrawerToggle}
            variant="temporary"
          >
            <List>
              <ListItem button onClick={handlePageChange('/')} style={{justifyContent: "center"}}>
                <img src={whiteTree} alt="" className={classes.treeImage}/>
              </ListItem>
              <Divider variant="middle" classes={{root: classes.divider}}/>
              <ListItem button onClick={handlePageChange('/')}>
                <ListItemText primary="Home" classes={{primary:classes.listItemText}}/>
              </ListItem>
              <ListItem button onClick={handlePageChange('/bio')}>
                <ListItemText primary="Bio" classes={{primary:classes.listItemText}}/>
              </ListItem>
              <ListItem button onClick={handlePageChange('/gallery')}>
                <ListItemText primary="Gallery" classes={{primary:classes.listItemText}}/>
              </ListItem>
              <ListItem button onClick={handlePageChange('/events')}>
                <ListItemText primary="Events" classes={{primary:classes.listItemText}}/>
              </ListItem>
              <ListItem button onClick={handlePageChange('/contact')}>
                <ListItemText primary="Contact" classes={{primary:classes.listItemText}}/>
              </ListItem>
              <Divider variant="middle" classes={{root: classes.divider}}/>
              <ListItem disabled />
              <ListItem>
                <SocialButtons spacing={0} />
              </ListItem>
              <ListItem disabled >
                <Box style={{height: "50px"}}/>
              </ListItem>
            </List>
          </Drawer>
          </Hidden>

        </Toolbar>
      </AppBar>
    </div>
  );
}
