import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from '@material-ui/core/Hidden';
import SocialButtons from "../SocialButtons/index";
import "./index.scss";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "50px",
    [theme.breakpoints.down('sm')]: {
      minHeight: "65px",
    },
  },
  toolBar: {
    minHeight: "50px",
    [theme.breakpoints.down('sm')]: {
      minHeight: "65px",
    },
  },
  socialIcons: {
    backgroundColor: '#081797', 
    borderRadius: "16%", 
    borderColor: '#ffffff', 
    borderStyle: "solid", 
    borderWidth: "2px",
    hover: theme.palette.secondary.hover
  },
  socialButton: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.hover,
    },
    height: "40px",
    minWidth: "30px",
  },
  mainGrid: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    },
  },
  copyrightGrid: {
    width: "100px",
    height: "25px",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      height: "7px",
    },
  },
  copyrightGridMobile: {
    width: "100%",
    height: "25px",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      height: "10px",
    },
  },
  copyrightText: {
    fontSize: "0.4em",
    position: "absolute",
    bottom: 0,
  },
  copyrightTextMobile: {
    fontSize: "7px",
    letterSpacing: "2.5px",
    position: "absolute",
    bottom: 0,
  },
  toolbarGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      flexDirection: "column",
    },
  }
}));

export default function FooterBar(props) {
  const classes = useStyles();

  const socialButtonSpacing = props.isMobile ? 2 : 1

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar} >
        <Toolbar className={classes.toolBar}>
          <Grid container className={classes.toolbarGrid}>
            
            <Hidden smDown>
              <Grid container className={classes.copyrightGrid}>
                <Typography className={classes.copyrightText} variant="body2" color="secondary">
                  © 2021 Blue Arbutus
                </Typography>
              </Grid>
            </Hidden>
            
            {/* <Grid container className={classes.mainGrid}> */}
              <SocialButtons 
                name={classes.mainGrid} 
                spacing={socialButtonSpacing} 
                // style={{backgroundColor: "yellow", width: "auto%"}}
              />
            {/* </Grid> */}
            
            <Hidden mdUp>
              <Grid container className={classes.copyrightGridMobile}>
                <Typography className={classes.copyrightTextMobile} color="secondary">
                  © 2021 Blue Arbutus
                </Typography>
              </Grid>
            </Hidden>

          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
