import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ReactAnimatedEllipsis from 'react-animated-ellipsis';

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "absolute",
    },
    bodyText: {
        textAlign: "justify",
        fontSize: window.innerWidth > 400 ? "1.75em" : "1.25em",
        fontFamily: "davida",
        color: theme.palette.primary.main
    },
}));

export default function Loading() {
    const classes = useStyles();

    const loadingText1 = "loading some sweet";
    const loadingText2 = "sweet band pics";

    const ellipsisFontSize = window.innerWidth > 400 ? "1.75em" : "1.25em"

    return (
        <div className={classes.mainDiv}>
            <Typography className={classes.bodyText} variant="body1" color="primary">{loadingText1}</Typography>
            <Typography className={classes.bodyText} variant="body1" color="primary">{loadingText2}</Typography>
            <ReactAnimatedEllipsis
                fontSize= {ellipsisFontSize}
                spacing="0.6rem"
                className={classes.bodyText}
                style={{marginTop: -4}}
            />
        </div>
    )
}